.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 12px;
    z-index: 3;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    background: white;
    color: black;
    box-sizing: border-box;
}

.copyright {

}

.patent {

}