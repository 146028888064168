.app {
    display: flex;
    flex-flow: row nowrap;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background: #010101;
}

.app-content {
    display: flex;
    flex-flow: column wrap;
    box-sizing: border-box;
    width: 100%;
}

.app-demo {
    width: 100%;
    box-sizing: border-box;
}
