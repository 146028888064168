.video-container {
    position: relative;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}

.buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: row wrap;
    color: white;
    z-index: 3;
}

.analysis-btn {
    cursor: pointer;
    padding: 5px 10px;
    margin: 12px;
    border: 1px solid white;
    border-radius: 25px;
}

.learn-more-btn {
    cursor: pointer;
    padding: 5px 10px;
    margin: 12px;
    border: 1px solid white;
    border-radius: 25px;
}

.video-ai {
    /*position: relative;*/
    border-radius: 50%;
    /*background: tran*/
    /*object-fit: contain;*/
}